/* eslint no-console:0 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { computed, createApp, defineAsyncComponent, nextTick, onMounted, ref, watch } from 'vue';
import Rails from 'rails-ujs';
import Vue3TouchEvents from 'vue3-touch-events';
import polyfill from '../src/polyfill/closest';
import adsUtils from '../src/utils/ads_utils';
import GaUtils from '../src/utils/ga_utils';
import ImageUtils from '../src/utils/image_utils';
import type { Ref } from 'vue';

polyfill(window);

// NOTE: Call before main code
Rails.start();

// Init
/* eslint-disable no-new */
const app = createApp({
  components: {
    SeeMoreButton: defineAsyncComponent(
      () => import(/* webpackChunkName: "see_more_button" */ '../src/components/see_more_button.vue'),
    ),
    JumpToTopButton: defineAsyncComponent(
      () => import(/* webpackChunkName: "jump_to_top_button" */ '../src/components/jump_to_top_button.vue'),
    ),
    SwipeImages: defineAsyncComponent(
      () => import(/* webpackChunkName: "swipe_images" */ '../src/components/swipe_images.vue'),
    ),
    Announcement: defineAsyncComponent(
      () => import(/* webpackChunkName: "announcement" */ '../src/components/announcement.vue'),
    ),
    UserProfileModal: defineAsyncComponent(
      () => import(/* webpackChunkName: "user_profile_modal" */ '../src/components/users/user_profile_modal.vue'),
    ),
    ShopPriceListModal: defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "shop_price_list_modal" */ '../src/components/products/shop_price_list_modal.vue'),
    ),
    LightboxImages: defineAsyncComponent(
      () => import(/* webpackChunkName: "light_box_images" */ '../src/components/lightbox.vue'),
    ),
  },
  setup() {
    const { fitToWidthImage, fitImageSize, addFittingImageSizeClass } = ImageUtils();
    const { gaEvent } = GaUtils();
    const { loadAds } = adsUtils();
    const clientWidth = ref((window as any).document.body.clientWidth);
    const appPromotionBarRef = ref<HTMLElement>();
    const headerRef = ref<HTMLElement>();
    const userDescriptionRef = ref<HTMLElement>();
    const userDescriptionSpRef = ref<HTMLElement>();
    const userNameRef = ref<HTMLElement>();
    const userProfileImageRef = ref<HTMLElement>();
    const headerUserProfileImageRef = ref<HTMLElement>();
    const headerUserProfileImageSpRef = ref<HTMLElement>();
    const isMenuDisplaying = ref(false);
    const isModalDisplaying = ref(false);
    const scrollY = ref(0);

    const isMobile = computed(() => clientWidth.value <= 768);
    const isTablet = computed(() => clientWidth.value > 768 && clientWidth.value < 1025);
    const isDesktop = computed(() => clientWidth.value >= 1025);

    const getRefDomEle = (refTag: Ref<HTMLElement | undefined>) => refTag.value as unknown as HTMLElement;
    const adsPageScrollFollowing = (targetSelector: string) => {
      const target = document.querySelector<HTMLElement>(targetSelector);
      if (!target) return;
      const side = target.querySelector<HTMLElement>('.l-side');
      if (!side) return;
      const sideAds = side.querySelector<HTMLElement>('ins');
      if (!sideAds) return;
      const $headerEle = document.querySelector<HTMLElement>('.l-header');
      const $breadcrumbEle = document.querySelector<HTMLElement>('.p-breadcrumb');
      if ($headerEle && $breadcrumbEle) {
        const headerHeight: number = $headerEle.clientHeight;
        const navHeight: number = $breadcrumbEle.clientHeight;
        const contentStart: number = headerHeight + navHeight;
        const contentHeight: number = target.clientHeight;
        const contentEnd: number = contentStart + contentHeight;
        const bottomPadding = 20;
        if (window.pageYOffset < contentStart) {
          sideAds.style.position = 'relative';
          sideAds.style.top = 'auto';
          sideAds.style.bottom = '0';
        } else if (
          window.pageYOffset >= contentStart &&
          window.pageYOffset > contentEnd - sideAds.clientHeight - bottomPadding - 10
        ) {
          sideAds.style.position = 'absolute';
          sideAds.style.top = 'auto';
          sideAds.style.bottom = `${bottomPadding}px`;
        } else {
          sideAds.style.position = 'fixed';
          sideAds.style.top = '74px';
          sideAds.style.bottom = '0';
        }
      }
    };
    const setPageScrollEvents = () => {
      document.body.onscroll = () => {
        adsPageScrollFollowing('.ads-following');
      };
    };

    const setCategoryMenuEvents = () => {
      if (document.getElementById('category-menu')) {
        document.body.addEventListener('keyup', (event) => {
          if (event.code === 'Escape') {
            isMenuDisplaying.value = false;
          }
        });

        document.addEventListener('click', (event: Event) => {
          let target = event.target as HTMLElement;
          let isClickedInMenu = false;
          while (target.parentElement) {
            if (target.parentElement.getAttribute('id') === 'category-menu') {
              isClickedInMenu = true;
              break;
            }
            target = target.parentElement;
          }
          if (!isClickedInMenu) {
            isMenuDisplaying.value = false;
          }
        });
      }
    };
    const onScroll = () => {
      const appPromotionBar = getRefDomEle(appPromotionBarRef);
      if (!appPromotionBar) {
        return;
      }
      if (window.scrollY > appPromotionBar.getBoundingClientRect().height) {
        scrollY.value = window.scrollY;
      }
    };

    watch(scrollY, (newValue, oldValue) => {
      const appPromotionBar = getRefDomEle(appPromotionBarRef);
      if (appPromotionBar) {
        if (headerRef.value) {
          headerRef.value.style.top =
            newValue < oldValue ? '0px' : `-${appPromotionBar.getBoundingClientRect().height}px`;
        }
      }
    });

    onMounted(() => {
      // Set scroll events
      setPageScrollEvents();
      // Load ads
      loadAds();
      setTimeout(() => {
        loadAds();
      }, 1000);
      // set resize event
      nextTick(() => {
        window.addEventListener('resize', () => {
          clientWidth.value = window.document.body.clientWidth;
        });
      });
      // set ESC press event to close category menu
      setCategoryMenuEvents();
      // show app promotion bar
      if (isMobile.value || isTablet.value) {
        window.addEventListener('load', onScroll);
        window.addEventListener('scroll', onScroll);
      }
    });

    const sanitize = (str: string) => {
      const dummy = document.createElement('dummy');
      dummy.innerHTML = str;
      const scripts = dummy.getElementsByTagName('script');
      for (let i = 0, l = scripts.length; i < l; i += 1) {
        dummy.removeChild(scripts[i]);
      }
      return dummy.innerText;
    };
    const autolink = async (str: string) => {
      const module = await import(/* webpackChunkName: "Autolinker" */ 'autolinker');
      return module.default.link(str, {
        phone: false,
        stripPrefix: false,
        stripTrailingSlash: false,
        className: '',
        replaceFn: (match: any) => {
          const tag = match.buildTag();
          tag.setAttr('rel', 'nofollow noopener');
          return tag;
        },
      });
    };
    const onRefreshProfile = (event: { description: string; name: string; image: { url: string } }) => {
      // for emit('refresh-profile') in child components
      const userDescription = getRefDomEle(userDescriptionRef) as HTMLParagraphElement;
      const userDescriptionSp = getRefDomEle(userDescriptionSpRef) as HTMLParagraphElement;
      const userName = getRefDomEle(userNameRef) as HTMLParagraphElement;
      const userProfileImage = getRefDomEle(userProfileImageRef) as HTMLImageElement;
      const headerUserProfileImage = getRefDomEle(headerUserProfileImageRef) as HTMLImageElement;
      const headerUserProfileImageSp = getRefDomEle(headerUserProfileImageSpRef) as HTMLImageElement;
      autolink(sanitize(event.description)).then((description: string) => {
        userDescription.innerHTML = description;
        userDescriptionSp.innerHTML = description;
      });
      if (userName) {
        userName.innerText = event.name;
      }
      if (userProfileImage) {
        userProfileImage.srcset = event.image.url;
      }
      if (headerUserProfileImage) {
        headerUserProfileImage.srcset = event.image.url;
      }
      if (headerUserProfileImageSp) {
        headerUserProfileImageSp.srcset = event.image.url;
      }
    };

    const toggleCategoryMenu = () => {
      if (isMobile.value) {
        isModalDisplaying.value = !isModalDisplaying.value;
      } else {
        isMenuDisplaying.value = !isMenuDisplaying.value;
      }
    };
    const postSearchForm = (e: Event) => {
      const target = e.target as HTMLInputElement;
      const sort: string = target.value;
      const qs: string = window.location.search.slice(1);
      const qss: Array<string> = qs.split('&');
      const queries: any = { sort: '' };
      for (let i = 0, l = qss.length; i < l; i += 1) {
        const [key, value] = qss[i].split('=');
        queries[key] = value;
      }
      queries.sort = sort;
      window.location.search = Object.keys(queries)
        .map((key) => `${key}=${queries[key]}`)
        .join('&');
    };
    const toggleCategoryAccordion = (e: Event) => {
      const currentTarget = e.currentTarget as HTMLElement;
      const $closestEle = currentTarget.closest('ul');
      if (!$closestEle) return;

      const parentCategory = $closestEle.children as HTMLCollectionOf<HTMLElement>;
      if (currentTarget.className !== 'is-active') {
        currentTarget.classList.add('is-active');
        for (let i = 0; i < parentCategory.length; i += 1) {
          const item = parentCategory.item(i);
          if (item && item.localName === 'ul') {
            item.style.display = 'block';
          }
        }
      } else {
        currentTarget.classList.remove('is-active');
        for (let i = 0; i < parentCategory.length; i += 1) {
          const item = parentCategory.item(i);
          if (item && item.localName === 'ul') {
            item.style.display = 'none';
          }
        }
      }
    };

    return {
      isMenuDisplaying,
      isModalDisplaying,
      clientWidth,
      userNameRef,
      appPromotionBarRef,
      headerRef,
      userDescriptionRef,
      userDescriptionSpRef,
      userProfileImageRef,
      headerUserProfileImageRef,
      headerUserProfileImageSpRef,
      isMobile,
      isTablet,
      isDesktop,
      gaEvent,
      fitToWidthImage,
      fitImageSize,
      addFittingImageSizeClass,
      onRefreshProfile,
      toggleCategoryAccordion,
      postSearchForm,
      toggleCategoryMenu,
    };
  },
});
app.use(Vue3TouchEvents);
app.mount('#body-wrapper');
