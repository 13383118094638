export default (): any => {
  const loadAds = () => {
    if ((window as any).adsbygoogle) {
      const ads = document.getElementsByTagName('ins');
      for (let i = 0, l = ads.length; i < l; i += 1) {
        try {
          const adsbygoogle = (window as any).adsbygoogle || [];
          adsbygoogle.push({});
        } catch (e) {
          // noop
        }
      }
    }
    if ((window as any).googletag) {
      // NOTE: 下部固定オーバーレイ
      const divGptAd1684463101354 = document.getElementById('div-gpt-ad-1684463101354-0');
      if (divGptAd1684463101354 && !divGptAd1684463101354.innerHTML) {
        googletag.cmd.push(function () {
          googletag.display('div-gpt-ad-1684463101354-0');
        });
        if (divGptAd1684463101354.parentElement) {
          const fixedBanner = document.getElementsByClassName('p-fixedBanner');
          if (fixedBanner[0]) {
            divGptAd1684463101354.parentElement.style.display = 'none';
          } else {
            divGptAd1684463101354.parentElement.style.display = 'block';
          }
        }
      }
    }
  };
  return {
    loadAds,
  };
};
